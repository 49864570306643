import React, { useContext, useEffect, useState } from "react";
// import "../assets/css/dashboard.scss";
import { Link, NavLink } from "react-router-dom";
import ic_trucks from "../assets/images/Dashboard/ic_trucks.svg";
import ic_drivers from "../assets/images/Dashboard/ic_drivers.svg";
import ic_contracts_1 from "../assets/images/Dashboard/ic_contracts_1.svg";
import ic_contracts from "../assets/images/Dashboard/ic_contracts.svg";
import { DateRangePicker } from "rsuite";
import $ from "jquery";
import ic_call from "../assets/images/Dashboard/ic_call.svg";
import user_1 from "../assets/images/Dashboard/user_1.svg";
import user_2 from "../assets/images/Dashboard/user_2.svg";
import user_3 from "../assets/images/Dashboard/user_3.svg";
import banner_graphic from "../assets/images/Dashboard/banner_graphic.svg";
import Chart from "react-apexcharts";
import { AppContext } from "../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import moment from "moment";

const Dashboard = () => {
  const [show, setShow] = useState(true);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const { mainClass, customerData, ApiCaaling, setApiCalling, profileDetails } =
    useContext(AppContext);
  const [graphDetails, setGraphDetails] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    colors: ["#c22e37"],
  });
  const [rateChartList, setRateChartList] = useState([]);
  const [tariffExportList, setTariffExportList] = useState([]);
  const [tariffImprotList, setTariffImportList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [dashboardData, setDashboardData] = useState({});

  const getDashboardData = () => {
    setApiCalling(true);

    postWithAuthCallWithErrorResponse(
      ApiConfig.DASHBOARD,
      JSON.stringify({
        ...customerData,
        from_date: dates.startDate,
        to_date: dates.endDate,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setApiCalling(false);
        if (res.json.result) {
          setDashboardData(res.json);
          setGraphDetails({
            ...graphDetails,
            options: {
              ...graphDetails.options,
              xaxis: {
                categories: res.json.graph_details.graph.map(
                  (data) => data.date
                ),
              },
            },

            series: [
              {
                name: "freights",
                data: res.json.graph_details.graph.map((data) => {
                  return data.freights;
                }),
              },
            ],
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getDashboardData();
  }, [dates]);
  useEffect(() => {
    RateChartList();
    TarrifExportList();
    TarrifImportList();
  }, []);

  const RateChartList = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.RATE_CHART_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setRateChartList(res.json.rate_chart_list);
      setApiCalling(false);
    });
  };
  const TarrifExportList = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TARRIF_EXPORT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setTariffExportList(res.json.tariff_export_list);
      setApiCalling(false);
    });
  };
  const TarrifImportList = () => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TARRIF_IMPORT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setTariffImportList(res.json.tariff_import_list);
      setApiCalling(false);
    });
  };

  return (
    <>
      {/* <Sidebar/>
         <LinkbhayHeader/> */}
      <main
        className={`main-section-dashboard scroll-style ${mainClass} `}
        id="cxMain"
      >
        <section className="container-xxl">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-content">
                <div className="row">
                  <div className="col-lg-12">
                    <section className="banner-dashboard">
                      <div className="row">
                        <div className="col-lg-7 col-sm-7 banner-left">
                          <span>Welcome back,</span>
                          <h3 className="heading-banner">
                            {profileDetails.profile &&
                              profileDetails.profile.user_name}
                          </h3>
                          <ul className="paragarph-banner">
                            {/* <NavLink to="/ApprovalHeader">
                              <li>
                                Admin can upload Contract after reviewing all
                                the document
                              </li>
                            </NavLink> */}
                            <NavLink to="/ApprovalHeader">
                              <li>
                                Admin approve shippers and delivery to use
                                Abay system
                              </li>
                            </NavLink>
                            <NavLink to="/VehicleRequest">
                              <li>Admin can approve Vehicle Request</li>
                            </NavLink>
                            <NavLink to="/OfferVehicles">
                              <li>Admin can view response and send offer</li>
                            </NavLink>
                            <NavLink to="/DirectOrderConfirmation">
                              <li>
                                Admin Sends Order confirmation to Shipper and
                                Delivery
                              </li>
                            </NavLink>
                            {/* <NavLink to="/OfferVehicles">
                              <li>Admin can assign vehicle to cargo</li>
                            </NavLink> */}
                            <NavLink to="#">
                              <li>Admin can download all the document</li>
                            </NavLink>
                          </ul>
                          <div>
                            <Link to="/Notifications" className="get-start-btn">
                              Get started by checking notifications
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-5 col-sm-5 banner-right">
                          <img
                            className="banner-img"
                            src={banner_graphic}
                            alt=""
                          />
                        </div>
                      </div>
                    </section>
                    <div className="dashboard-card">
                      <div className="row">
                        {/* left card */}
                        <div className="col-lg-6 ">
                          <div className=" card left-card-section mt-4 card-style">
                            <div className="graph-chart ">
                              <div className="card-body">
                                <div className="top-content d-flex justify-content-between hr-line pb-2 mb-2">
                                  <div className="top-left"></div>
                                  <div className="top-right d-flex">
                                    <p className="m-0 mt-2 me-2">From</p>
                                    <input
                                      className="form-control"
                                      type="date"
                                      value={dates.startDate}
                                      onChange={(e) => {
                                        // setErrMsg({ ...errMsg, username: "" });
                                        setDates({
                                          ...dates,
                                          startDate: e.target.value,
                                        });
                                      }}
                                    />
                                    <p className="ms-2 mt-2 me-2">to</p>
                                    <input
                                      className="form-control"
                                      type="date"
                                      value={dates.endDate}
                                      onChange={(e) => {
                                        // setErrMsg({ ...errMsg, username: "" });
                                        setDates({
                                          ...dates,
                                          endDate: e.target.value,
                                        });
                                      }}
                                    />
                                    {/* <DateRangePicker placeholder="Select Date Range" /> */}
                                  </div>
                                </div>

                                <div className="middel-content chart">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <Chart
                                        options={graphDetails.options}
                                        series={graphDetails.series}
                                        type="line"
                                        height={200}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="bottom-content d-flex justify-content-between">
                                  <div className="left">
                                    <h5 className="left-count">
                                      {graphDetails &&
                                      graphDetails.total_freights
                                        ? graphDetails.total_freights
                                        : 0}
                                    </h5>
                                    <label>Total freights</label>
                                  </div>
                                  <div className="center">
                                    <h5 className="left-count">
                                      {graphDetails &&
                                      graphDetails.this_week_count
                                        ? graphDetails.this_week_count
                                        : 0}
                                    </h5>
                                    <label>Delivered This week</label>
                                  </div>
                                  <div className="right">
                                    <h5 className="left-count">
                                      {graphDetails &&
                                      graphDetails.total_revenue
                                        ? graphDetails.total_revenue
                                        : 0}
                                    </h5>
                                    <label>Total revenue</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* right card */}
                        <div className="col-lg-6">
                          <div className="right-card-section">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 purple-body">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div className="card-body d-flex justify-content-between hr-line pb-2">
                                      <div>
                                        <h4 className="purple">
                                          {dashboardData.vehicles}
                                        </h4>
                                        <p className="count-name">Vehicles</p>
                                      </div>

                                      <div>
                                        <img src={ic_trucks} alt="" />
                                      </div>
                                    </div>

                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left">
                                        <label className="active">Active</label>
                                        <span className="active-count">
                                          {" "}
                                          {dashboardData.active_vehicles}
                                        </span>
                                      </div>
                                      <div className="right">
                                        <Link to="/VehicleList">View All</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 orange-body">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div className="card-body d-flex justify-content-between hr-line pb-2">
                                      <div>
                                        <h4 className="orange">
                                          {dashboardData.vehicle_requests}
                                        </h4>
                                        <p className="count-name">
                                          Vehicle Requests
                                        </p>
                                      </div>

                                      <div>
                                        <img src={ic_drivers} alt="" />
                                      </div>
                                    </div>

                                    <div className="card-body bottom d-flex justify-content-between">
                                      {/* <div className="left">
                                        <label className="active">Active</label>
                                        <span className="active-count">
                                          {" "}
                                          {
                                            dashboardData.active_vehicle_requests
                                          }
                                        </span>
                                      </div> */}
                                      <div className="right">
                                        <Link to="/VehicleRequest">
                                          View All
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 green-body">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div className="card-body d-flex justify-content-between hr-line pb-2">
                                      <div>
                                        <h4 className="green">
                                          {dashboardData.all_transporters}
                                        </h4>
                                        <p className="count-name">
                                          Transporters
                                        </p>
                                      </div>

                                      <div>
                                        <img src={ic_contracts} alt="" />
                                      </div>
                                    </div>

                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left">
                                        <label className="active">Active</label>
                                        <span className="active-count">
                                          {" "}
                                          {dashboardData.transporters}
                                        </span>
                                      </div>
                                      <div className="right">
                                        <Link to="/DeliveryLists">
                                          View All
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6  blue-body">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div className="card-body d-flex justify-content-between hr-line pb-2">
                                      <div>
                                        <h4 className="blue">
                                          {dashboardData.all_shippers}
                                        </h4>
                                        <p className="count-name">Shippers</p>
                                      </div>

                                      <div>
                                        <img src={ic_contracts_1} alt="" />
                                      </div>
                                    </div>

                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left">
                                        <label className="active">Active</label>
                                        <span className="active-count">
                                          {" "}
                                          {dashboardData.shippers}
                                        </span>
                                      </div>
                                      <div className="right">
                                        <Link to="/ShippersLists">
                                          View All
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Ongoing and Notification card */}
                    <div className="details-card">
                      <div className="row">
                        {/* left card */}
                        {/* <div className="col-lg-6">
                          <div className="card card-style left-card-dashboard scroll-style mt-4">
                            <div className="card-body">
                              <div className="card_title d-flex justify-content-between hr-line pb-2 mb-3">
                                <h4 className="ongoing-heading common-heading">
                                  Ongoing Freights
                                </h4>
                                <div className="right-top d-flex justify-content-between ">
                                  <div>
                                    <Link
                                      to="/FreightsHeaders"
                                      className="blue_link m-2"
                                    >
                                      List View
                                    </Link>
                                  </div>
                                  <div>
                                    <Link
                                      to="/GPS_Shipper"
                                      className="blue_link m-2"
                                    >
                                      Map View
                                    </Link>
                                  </div>
                                </div>
                              </div>

                              <div className="user_block ">
                                {dashboardData.ongoing_freights &&
                                  dashboardData.ongoing_freights.length &&
                                  dashboardData.ongoing_freights.map((data) => {
                                    return (
                                      <div className="d-flex justify-content-between align-items-start pb-2 mb-3 hr-line">
                                        <div className="d-flex align-items-start">
                                          <img
                                            src={
                                              data.trip_image &&
                                              data.trip_image.length
                                                ? ApiConfig.BASE_URL_FOR_IMAGES +
                                                  data.trip_image
                                                : user_1
                                            }
                                            className="me-2 rounded-circle"
                                            alt="user"
                                            height="32"
                                            width="32"
                                          />
                                          <div className="ms-3">
                                            <span className="name">
                                              {data.trip_company_name}
                                            </span>
                                            <p className="location">
                                              {data.trip_start_city}{" "}
                                              {data.trip_start_country} -{" "}
                                              {data.trip_end_city}{" "}
                                              {data.trip_end_country}
                                            </p>
                                            <Link
                                              to={
                                                "/ViewDetails/" + data.trip_id
                                              }
                                              className="blue_link"
                                            >
                                              View Details
                                            </Link>
                                          </div>
                                        </div>
                                        <Link to="#">
                                          <img src={ic_call} alt="" />
                                        </Link>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* right card */}
                        {/* <div className="col-lg-6">
                          <div className=" card-style right-card-dashboard scroll-style mt-4">
                            <div className="card-body p-4">
                              <div className="card_title d-flex justify-content-between mb-3 hr-line pb-2">
                                <h4 className="ongoing-heading common-heading">
                                  Recent Activities
                                </h4>
                                <Link
                                  to="/Notifications"
                                  className="blue_link "
                                >
                                  View All
                                </Link>
                              </div>

                              <div className="notification-all ">
                                {dashboardData.notifications &&
                                  dashboardData.notifications.length &&
                                  dashboardData.notifications.map((data) => {
                                    return (
                                      <div className=" mb-3 hr-line pb-2">
                                        <h5 className="notification-heading">
                                          Lorem Ipsum{" "}
                                        </h5>
                                        <p className="notification-paragraph">
                                          {data.notification_text}
                                        </p>
                                        <p className="date-time text-end">
                                          {moment(
                                            data.notification_date +
                                              " " +
                                              data.notification_time
                                          ).format("MMMM Do YYYY, h:mm:ss a")}
                                        </p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* ======================= RATES ================ */}

                        <div className="rates-main">
                          <div className="transporter">
                            <div className="heading-top">
                              <div className="left">
                                Abay Plc Port and Terminal handling Rate
                                chart(Import/Export)
                              </div>
                              <div className="right">
                                <Link to="/TransporterRates">View All</Link>
                              </div>
                            </div>
                            <div className="offerLoadsMainLists">
                              <div
                                className="mainLoadTable"
                                style={{ overflow: "auto" }}
                              >
                                <table
                                  className="dataList"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        <label>S.No.</label>
                                      </th>
                                      <th>
                                        <label>Rate type /Category</label>
                                      </th>
                                      <th>
                                        <label>Currency</label>
                                      </th>
                                      <th>
                                        <label>20 ft(teu)</label>
                                      </th>

                                      <th>
                                        <label>40 ft(feu)</label>
                                      </th>
                                      <th className="">
                                        <label>Break bulk(freight tone)</label>
                                      </th>
                                      <th>
                                        <label>Bulk (tone)</label>
                                      </th>
                                      <th>
                                        <label>RORO(Unit)</label>
                                      </th>
                                      <th>
                                        <label>Other</label>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rateChartList.map(
                                      (rateListItem, index) => {
                                        return (
                                          <tr
                                            className="Rowbgcolor"
                                            key={"rateListItem" + index}
                                          >
                                            <td>
                                              <label>{index + 1}</label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  rateListItem.rate_chart_category
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {rateListItem.currency_code}
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {rateListItem.rate_chart_20ft}
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {rateListItem.rate_chart_40ft}
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {
                                                  rateListItem.rate_chart_break_bulk
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {rateListItem.rate_chart_bulk}
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {rateListItem.rate_chart_roro}
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {rateListItem.rate_chart_other}
                                              </label>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="shipper">
                            <div className="heading-top">
                              <div className="left">
                                Abay Logistics plc. Transport tariff
                                Chart(Import)
                              </div>
                              <div className="right">
                                <Link to="/ShipperRatesImport">View All</Link>
                              </div>
                            </div>
                            <div className="heading-top mt-3">
                              <div className="left">Departure--Djibouti</div>
                            </div>

                            <div className="offerLoadsMainLists">
                              <div
                                className="mainLoadTable"
                                style={{ overflow: "auto" }}
                              >
                                <table
                                  className="dataList"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        <label>S.No.</label>
                                      </th>
                                      <th>
                                        <label>Destination</label>
                                      </th>
                                      <th>
                                        <label>Km</label>
                                      </th>
                                      <th>
                                        <label>Currency</label>
                                      </th>

                                      <th>
                                        <label>Unit of Measurement</label>
                                      </th>
                                      <th className="">
                                        <label>Tariff (Birr) </label>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tariffImprotList.map(
                                      (tariffImprotListData, index) => {
                                        return (
                                          <tr
                                            className="Rowbgcolor"
                                            key={"tariffImprotListData" + index}
                                          >
                                            <td>
                                              <label>{index + 1}</label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffImprotListData.tariff_import_destination
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffImprotListData.tariff_import_km
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffImprotListData.currency_code
                                                }
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {tariffImprotListData.unit_name}
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {
                                                  tariffImprotListData.tariff_import_tariff
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label></label>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="shipper">
                            <div className="heading-top">
                              <div className="left">
                                Abay Logistics plc. Transport tariff
                                Chart(Export)
                              </div>
                              <div className="right">
                                <Link to="/ShipperRatesExport">View All</Link>
                              </div>
                            </div>
                            <div className="heading-top mt-3">
                              <div className="left">Departure--Djibouti</div>
                            </div>

                            <div className="offerLoadsMainLists">
                              <div
                                className="mainLoadTable"
                                style={{ overflow: "auto" }}
                              >
                                <table
                                  className="dataList"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        <label>S.No.</label>
                                      </th>
                                      <th>
                                        <label>Destination</label>
                                      </th>
                                      <th>
                                        <label>Km</label>
                                      </th>
                                      <th>
                                        <label>Currency</label>
                                      </th>

                                      <th>
                                        <label>Unit of Measurement</label>
                                      </th>
                                      <th className="">
                                        <label>Tariff (Birr) </label>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tariffExportList.map(
                                      (tariffExportListDAta, index) => {
                                        return (
                                          <tr
                                            className="Rowbgcolor"
                                            key={"tariffExportListDAta" + index}
                                          >
                                            <td>
                                              <label>{index + 1}</label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffExportListDAta.tariff_export_destination
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffExportListDAta.tariff_export_km
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffExportListDAta.currency_code
                                                }
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {tariffExportListDAta.unit_name}
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {
                                                  tariffExportListDAta.tariff_export_tariff
                                                }
                                              </label>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Dashboard;
